import { forkJoin } from 'rxjs';
import * as types from './types';

import { actions } from '../../utils';
import { returnItemFromState } from './selectors';
import { BASE_API_URL } from '../../../constants';

const superagent = require('superagent');

// Create our shorthand action dispatches
export const receiveRequest = (scope, data) => actions.returnDispatchSetRequest(types.RECEIVE_ItemCategories_REQUEST, scope, data);
export const setFetching = (scope, fetching) => actions.returnDispatchSetFetching(types.SET_ItemCategories_FETCHING, scope, fetching);
export const setLoading = (scope, loading) => actions.returnDispatchSetLoading(types.SET_ItemCategories_LOADING, scope, loading);
export const toggleBoolean = (scope, toggleDirection) => actions.returnDispatchSetBoolean(types.TOGGLE_ItemCategories_BOOLEAN, scope, toggleDirection);

// Use our shorthand for some basic actions
export const selectCategory = (product) => receiveRequest('selectedCategory', product);
export const selectProducts = (product) => receiveRequest('selectedProducts', product);

// Define any functions we need to run our dispatches
/**
 * Load all item categories from the API.
 *
 * @param storeNo
 * @param benefits
 * @returns {Promise<*|*[]>}
 */
async function getCategories(storeNo, benefits) {
  if (benefits == null) {
    return [];
  }

  const uniqueCatsAndSubCats = new Set();
  const currentDate = new Date((new Date()).toISOString().substring(0, 10));

  const validBenefits = benefits.filter((benefitGroup) => benefitGroup?.foodBenefitBalance != null && new Date(benefitGroup.beginBenefitDate) <= currentDate && new Date(benefitGroup.endBenefitDate) > currentDate);

  validBenefits.forEach((benefitGroup) => {
    benefitGroup.foodBenefitBalance.forEach((benefit) => {
      const { categoryCode, subcategoryCode } = benefit;

      uniqueCatsAndSubCats.add(`${categoryCode}-${subcategoryCode}`);
    });
  });

  const catsAndSubCats = Array.from(uniqueCatsAndSubCats);

  if (catsAndSubCats.length === 0) {
    return [];
  }

  const res = await superagent.get(`${BASE_API_URL}categories`).query({
    store: storeNo,
    'catsAndSubCats[]': catsAndSubCats
  })
    .set('Accept', 'application/json');

  return res.body.result || [];
}

/**
 * Load all products for a category from the API.
 *
 * @param storeNo
 * @param catID
 * @param subCatID
 * @returns {Promise<*|*[]>}
 */
async function getCategoryProducts(storeNo, catID, subCatID) {
  const res = await superagent.get(`${BASE_API_URL}category/${catID}/${subCatID}/products?store=${storeNo}`)
    .set('Accept', 'application/json');

  return res.body.result || [];
}

// Now create our actions which require us to dispatch back a fetch
/**
 *  Fetch all of our initial data
 *
 * @param storeNo
 * @param benefits
 * @returns {Function}
 */
export const fetchAllCategories = (storeNo, benefits) => (dispatch) => {
  // Mark that we are fetching things
  dispatch(setFetching('categories', true));
  dispatch(receiveRequest('lastBenefits', JSON.stringify(benefits)));
  dispatch(receiveRequest('lastStore', storeNo));

  return getCategories(storeNo, benefits).then((categories) => {
    // console.log('Category:Success => ', categories);
    if (categories.length > 0) {
      // Load products for each category
      const calls = [];

      categories.forEach((cat, catIndex) => {
        calls.push(getCategoryProducts(storeNo, cat.Cat, cat.SubCat));
        categories[catIndex].products = [];
      });

      forkJoin(calls).subscribe(
        (data) => {
          data.forEach((catResult, catIndex) => {
            if (catResult.length > 0) {
              catResult.forEach((product) => {
                product.catUOM = categories[catIndex].UOMSP;
                product.subid = categories[catIndex].SubCat;
                categories[catIndex].products.push(product);
              });
            }
          });
        },
        (err) => console.log(`error ${err}`),
        () => {
          dispatch(receiveRequest('categories', categories));
          dispatch(receiveRequest('selectedProducts', null));

          // Mark that we are finished fetching things
          dispatch(setFetching('categories', false));
        },
      );
    } else {
      dispatch(receiveRequest('categories', categories));
      dispatch(receiveRequest('selectedProducts', null));
      dispatch(setFetching('categories', false));
    }
  });
};

/**
 * Determines whether we need to fetch data from a section
 *
 * @param state
 * @param storeNo
 * @returns {boolean}
 */
function shouldFetchAllCategories(state, storeNo, benefits) {
  const data = returnItemFromState(state, 'categories');
  const lastBenefits = returnItemFromState(state, 'lastBenefits');
  const lastStore = returnItemFromState(state, 'lastStore');

  return (!data || lastStore !== storeNo || lastBenefits !== JSON.stringify(benefits));
}

/**
 *  Fetch all of our initial data
 *
 * @param storeNo
 * @param benefits
 * @returns {Function}
 */
export const fetchAllCategoriesIfNeeded = (storeNo, benefits) => (dispatch, getState) => {
  if (shouldFetchAllCategories(getState(), storeNo, benefits)) {
    dispatch(fetchAllCategories(storeNo, benefits));
  }
};
