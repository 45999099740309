import React from 'react';
import PropTypes from 'prop-types';
import './CartQty.scss';
import classNames from 'classnames';

function CartQty(props) {
  const { benefitsRemaining, cartBftQty, cartQty, className, price, uom } = props;
  const hasBenefits = benefitsRemaining > 0;

  return (
    <span className={classNames('CartQty', className)}>
      <span className={classNames('d-none', 'd-sm-inline')}>Available: </span>
      <span className={classNames({ 'text-success': hasBenefits, 'text-danger': !hasBenefits })}>
        {uom === '$$$' ? <> ${benefitsRemaining.toFixed(2)}</> : <> {benefitsRemaining.toFixed(2)} {uom}</>}
      </span>

      {cartQty > 0 && (
        <>
          <span className={classNames('d-none', 'd-md-inline')}> | </span>

          <span className={classNames('d-none', 'd-md-inline')}>Total: </span>
          <span className={classNames('d-none', 'd-md-inline', 'text-primary')}>
            {uom === '$$$' ? <> ${price.toFixed(2)}</> : <> {cartBftQty} {uom}</>}
          </span>
        </>
      )}
    </span>
  );
}

CartQty.propTypes = {
  benefitsRemaining: PropTypes.number,
  cartBftQty: PropTypes.string,
  cartQty: PropTypes.number,
  className: PropTypes.string,
  price: PropTypes.number,
  uom: PropTypes.string,
};

CartQty.defaultProps = {
  benefitsRemaining: 0,
  cartBftQty: null,
  cartQty: 0,
  className: '',
  price: 0,
  uom: null,
};

export default CartQty;
