import React, { useEffect, useMemo } from 'react';
import { Alert, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import './CartSummary.scss';
import { useNavigate } from 'react-router-dom';
import { CancelButton, CartCategoriesList, MainNavbar } from '../../components';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { WumeiDataSelectors } from '../../state/ducks/WumeiData';

function CartSummary() {
  const navigate = useNavigate();

  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const totalCartQty = useSelector((state) => ItemCategoriesDataSelectors.returnTotalCartCountFromState(state));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));

  const haveItemsInTheCart = (totalCartQty > 0);

  // Make sure we have the data needed for this page
  useEffect(() => {
    if (categories.length === 0) {
      navigate('/item-categories', { replace: true });
    }
  }, [categories, navigate]);

  // Get our details on benefits coverage
  const wentOverBenefitAllowance = useMemo(() => {
    let overBenefitAllowance = false;

    Object.entries(categories).forEach(([, category]) => {
      const { Cat, SubCat } = category;

      const { remaining } = benefitsByCategory[`cat-${Cat}`][`sub-${SubCat}`];

      if (remaining < 0) {
        overBenefitAllowance = true;
      }
    });

    return overBenefitAllowance;
  }, [categories, benefitsByCategory]);

  return (
    <div className="CartSummary">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          {haveItemsInTheCart ? (
            <CartCategoriesList categories={categories} />
          ) : (
            <div className="m-b-1">You have not added any items to your cart yet.</div>
          )}
          {wentOverBenefitAllowance && (
            <Alert color="danger">You benefits do not cover all the items in your cart. Please go back and remove any items that exceed your benefit coverage.</Alert>
          )}
          {haveItemsInTheCart && !wentOverBenefitAllowance && (
            <Button block color="primary" onClick={() => navigate('/order-info')}>
              <div className="icon">
                <i className="fas fa-check" />
              </div>
              Checkout
            </Button>
          )}
          <CancelButton className="m-t-2" />
        </div>
      </div>
    </div>
  );
}

export default CartSummary;
