import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment-timezone';
import PhoneInput from 'react-phone-input-2';
import { formatPhoneNumber } from 'react-phone-number-input';
import 'react-phone-input-2/lib/style.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import './OrderInfo.scss';
import { useNavigate } from 'react-router-dom';
import { CancelButton, MainNavbar } from '../../components';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { StoresDataSelectors } from '../../state/ducks/StoresData';
import { AuthDataSelectors } from '../../state/ducks/AuthData';
import { useInput } from '../../hooks/useInput';
import { OrdersDataOperations } from '../../state/ducks/OrdersData';
import { WumeiDataSelectors } from '../../state/ducks/WumeiData';

const MySwal = withReactContent(Swal);

function OrderInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const benefits = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefits'));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));
  const cartQtyByProduct = useSelector((state) => ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state));
  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const pickupDates = useSelector((state) => StoresDataSelectors.returnSelectedStorePickupDates(state));
  const pickupTimesForDate = useSelector((state) => (date) => StoresDataSelectors.returnSelectedStorePickupTimesForDate(state, date, 'LL'));
  const selectedProducts = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedProducts'));
  const selectedStore = useSelector((state) => StoresDataSelectors.returnItemFromState(state, 'selectedStore'));
  const selectedStoreAllowsCurbSidePickUp = useSelector((state) => StoresDataSelectors.returnSelectedStoreAllowsCurbSidePickUp(state));
  const session = useSelector((state) => AuthDataSelectors.userData(state));
  const totalCartQty = useSelector((state) => ItemCategoriesDataSelectors.returnTotalCartCountFromState(state));

  const { valid_timezones: storeTimezone = 'America/Los_Angeles' } = selectedStore || {};

  // Make sure we have the data needed for this page
  useEffect(() => {
    if (categories.length === 0) {
      navigate('/item-categories', { replace: true });
    }
  }, [categories, navigate]);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [storeIsOpen, setStoreIsOpen] = useState(true);
  const [pickupTimes, setPickupTimes] = useState({});

  // Form Elements
  const { value: name, bind: bindName } = useInput(session?.firstname || '');
  const { value: addressLine1, bind: bindAddressLine1 } = useInput(session?.street_1 || '');
  const { value: addressLine2, bind: bindAddressLine2 } = useInput(session?.street_2 || '');
  const { value: city, bind: bindCity } = useInput(session?.city || '');
  const { value: customerState, bind: bindCustomerState } = useInput(session?.state || '');
  const { value: zipCode, bind: bindZipCode } = useInput(session?.zipcode || '');
  const { value: phoneNo, setValue: setPhoneNo } = useInput(session?.phone_number || '');
  const { value: pickupDate, setValue: setPickupDate } = useInput(Moment.tz(storeTimezone).format('LL'));
  const { value: pickupTime, bind: bindPickupTime, setValue: setPickupTime } = useInput('1');
  const { value: isCurbSidePickUp, setValue: setIsCurbSidePickUp } = useInput('0');
  const { value: isDelivery, setValue: setIsDelivery } = useInput('0');
  const { value: sendTextConfirmation, setValue: setSendTextConfirmation } = useInput('1');
  const pickupOrDeliveryText = (isDelivery === '1') ? 'Delivery' : 'Pickup';
  const usStates = {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AS: 'American Samoa',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VI: 'Virgin Islands',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
  };

  const switchDeliveryMethods = (useDelivery) => {
    setIsCurbSidePickUp(0);
    setIsDelivery(useDelivery);
  };

  const haveItemsInTheCart = (totalCartQty > 0);
  const usersTime = Moment().tz(storeTimezone).format('MMM D, YYYY h:mm A'); // TODO: Right now this is a dependency in the arrays but it should be a param later to clean up the processing

  // Make sure that if our available dates change that we have the right one selected.
  useEffect(() => {
    if (pickupDates.length > 0 && pickupDates.indexOf(pickupDate) === -1) {
      setPickupDate(pickupDates[0]);
      setPickupTimes(pickupTimesForDate(pickupDates[0]));
    }
  }, [pickupDate, pickupDates, pickupTimesForDate, setPickupDate]);

  // Update our pickup times if the pickup date changes.
  useEffect(() => {
    setPickupTimes(pickupTimesForDate(pickupDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate, selectedStore, usersTime]);

  // Update our pickup times if the pickup date changes.
  useEffect(() => {
    setStoreIsOpen((pickupDates.length > 0 && Object.entries(pickupTimes).length > 0));
  }, [pickupDates, pickupTimes, setStoreIsOpen]);

  // Update our current pickup times if the pickup date or number of times changes.
  useEffect(() => {
    const times = Object.keys(pickupTimes);

    if (times.length > 0) {
      setPickupTime(times[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate, JSON.stringify(pickupTimes), setPickupTime]);

  // Trigger errors if they are set
  useEffect(() => {
    if (error.length > 0) {
      MySwal.fire({
        icon: 'error',
        text: error,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setError('');
        }
      });
    }
  }, [error]);

  /**
     * Verify that the selected date is valid.
     *
     * @param showAlerts
     * @returns {string}
     */
  const checkPickupTime = useCallback((showAlerts = true) => {
    let errors = '';

    if (pickupTime.length === 0) {
      return '';
    }

    // console.log({
    //   currentTime: Moment().format('MMM D, YYYY h:mm A'),
    //   currentTimeInLA: Moment().tz('America/Los_Angeles').format('MMM D, YYYY h:mm A'),
    //   currentTimeInStoreTimezone: Moment().tz(storeTimezone).format('MMM D, YYYY h:mm A'),
    //   currentTimeInStoreTimezonePlus30: Moment().tz(storeTimezone).add('30', 'minutes').format('MMM D, YYYY h:mm A'),
    //   selectedTime: pickupTime,
    //   selectedTimeWithDate: `${pickupDate} ${pickupTime}`,
    //   selectedTimeAsMoment: Moment(pickupTime, ['h:m a', 'H:m']).format(),
    //   selectedTimeInLA: Moment.tz(pickupTime, ['h:m a', 'H:m'], 'America/Los_Angeles').format(),
    //   selectedTimeInStoreTimezone: Moment.tz(pickupTime, ['h:m a', 'H:m'], storeTimezone).format(),
    //   isBefore: Moment.tz(pickupTime, ['h:m a', 'H:m'], storeTimezone).isBefore(Moment().tz(storeTimezone).add('30', 'minutes'))
    // });

    if (Moment.tz(`${pickupDate} ${pickupTime}`, ['MMM D, YYYY h:m a', 'MMM D, YYYY H:m'], storeTimezone).isBefore(Moment().tz(storeTimezone).add('30', 'minutes'))) {
      errors = `Please enter a time after ${Moment().tz(storeTimezone).add('30', 'minutes').format('MMM D, YYYY h:mm A')}.`;
    }

    if (showAlerts && errors.length > 0) {
      setError(errors);
    }

    return errors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate, pickupTime, storeTimezone, usersTime]);

  const clickedOnSave = useCallback(() => {
    if (name.length === 0) {
      setError('Please enter valid Name.');
      return;
    }

    if (phoneNo.length === 0) {
      setError('Please enter valid Phone No.');
      return;
    }

    if (pickupDate == null) {
      setError('Please enter a valid Pick Up Date and Time.');
      return;
    }

    // Check home delivery fields
    if (isDelivery === '1') {
      if (addressLine1.length === 0) {
        setError('Please enter valid Address Line 1.');
        return;
      }

      if (city.length === 0) {
        setError('Please enter valid City.');
        return;
      }

      if (customerState.length === 0) {
        setError('Please enter valid State.');
        return;
      }

      if (zipCode.length === 0) {
        setError('Please enter valid Zip Code.');
        return;
      }
    }

    const timeErrors = checkPickupTime(false);

    if (timeErrors.length > 0) {
      setError(timeErrors);
      return;
    }

    // Total up the products from all subcategories
    const totalProductsForOrder = {};

    selectedProducts.products.forEach((product, index) => {
      const { cart_quantity, catid, prodkey, subid } = product;
      const qty = Number(cart_quantity);
      const uniqKey = `${catid}-${subid}-${prodkey}`;

      if (cart_quantity > 0) {
        if (totalProductsForOrder[uniqKey] !== undefined) {
          totalProductsForOrder[uniqKey].qty += qty;
          return;
        }

        totalProductsForOrder[uniqKey] = {
          id: prodkey,
          cat_id: catid,
          subcat_id: subid,
          qty,
        };
      }
    });

    const productsForOrder = Object.values(totalProductsForOrder);

    const itemsByCat = [productsForOrder];
    const pickupAt = `${Moment(pickupDate, 'LL').format('YYYY-MM-DD')}T${pickupTime}${Moment.tz(pickupDate, 'LL', storeTimezone).format('Z')}`;
    const currentDate = new Date((new Date()).toISOString().substring(0, 10));
    const validBenefits = benefits.filter((benefitGroup) => benefitGroup?.foodBenefitBalance != null && new Date(benefitGroup.beginBenefitDate) <= currentDate && new Date(benefitGroup.endBenefitDate) > currentDate);
    const benefitsEndDate = validBenefits[0].endBenefitDate;

    const orderDetails = {
      store_no: selectedStore.store_no || null,
      name,
      phone_number: phoneNo.includes('+') ? formatPhoneNumber(phoneNo) : formatPhoneNumber(`+${phoneNo}`),
      pickup_at: pickupAt,
      is_curbside_pick_up: isCurbSidePickUp,
      send_text_confirmation: (sendTextConfirmation === '1') ? 1 : 0,
      items_by_cat: itemsByCat,
      benefits: benefitsByCategory,
      benefitsEndDate,
      street_1: addressLine1,
      street_2: addressLine2,
      city,
      state: customerState,
      zipcode: zipCode,
      is_delivery: isDelivery,
      is_wic: 1,
    };

    MySwal.fire({
      title: 'Submit Order',
      icon: 'question',
      text: 'Are you sure you want to submit your order? Your WIC card will be charged for products in your order.',
      showCancelButton: true,
      confirmButtonText: 'Yes, Submit It',
      cancelButtonText: 'No, Keep Working',
    }).then((swalResult) => {
      if (swalResult.isConfirmed) {
        setIsLoading(true);

        dispatch(OrdersDataOperations.sendOrder(orderDetails)).then((result) => {
          if (result.errors.length > 0) {
            setError(result.errors.join(' '));
            setIsLoading(false);
          } else {
            navigate('/thank-you', { replace: true });
          }
        }).catch((orderError) => {
          setError(orderError);
          setIsLoading(false);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartQtyByProduct, categories, checkPickupTime, dispatch, navigate, isCurbSidePickUp, name, phoneNo, pickupDate, pickupTime, selectedStore, sendTextConfirmation, storeTimezone, usersTime]);

  // useLayoutEffect(() => {
  // checkPickupTime();
  // }, [checkPickupTime, pickupDate, pickupTime, usersTime]);

  let content = '';

  if (isLoading) {
    content = (<span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>);
  } else if (storeIsOpen) {
    const deliveryItemBlocks = (
      <>
        <FormGroup>
          <Label className="font-weight-bold">Address Line 1</Label>
          <Input {...bindAddressLine1} />
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold">Address Line 2 <small>(Optional)</small></Label>
          <Input {...bindAddressLine2} />
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold">City</Label>
          <Input {...bindCity} />
        </FormGroup>
        <Row className="mb-5">
          <Col md="6">
            <Label className="font-weight-bold">State</Label>
            <Input
              type="select"
              {...bindCustomerState}
            >
              {Object.entries(usStates).map(([abbrev, stateName]) => (
                <option key={abbrev} value={abbrev}>{stateName}</option>
              ))}
            </Input>
          </Col>
          <Col md="6">
            <Label className="font-weight-bold">Zip Code</Label>
            <Input {...bindZipCode} />
          </Col>
        </Row>
      </>
    );
    const pickupItemBlocks = (
      <div className="list m-b-1 m-t-1">
        <Label className="font-weight-bold">Pickup Type</Label>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              value="0"
              checked={isCurbSidePickUp === '0'}
              onChange={(e) => setIsCurbSidePickUp(e.target.value)}
            />{' '}
            In Store
          </Label>
        </FormGroup>

        {selectedStoreAllowsCurbSidePickUp && (
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              value="1"
              checked={isCurbSidePickUp === '1'}
              onChange={(e) => setIsCurbSidePickUp(e.target.value)}
            />{' '}
            Curbside
          </Label>
        </FormGroup>
        )}
      </div>
    );

    content = (
      <div className="list order-info-list">
        {haveItemsInTheCart ? (
          <Form onSubmit={(e) => {
            e.preventDefault();
          }}
          >
            <FormGroup>
              <Label className="font-weight-bold">First Name</Label>
              <Input type="text" {...bindName} />
            </FormGroup>
            <FormGroup>
              <Label className="font-weight-bold">Phone No</Label>
              <PhoneInput
                country="us"
                onlyCountries={['us', 'mx']}
                value={phoneNo}
                onChange={setPhoneNo}
              />
            </FormGroup>
            <div>
              <Label className="font-weight-bold">Order {pickupOrDeliveryText} Date</Label>
              {pickupDates.map((availableDate) => (
                <FormGroup key={availableDate} check>
                  <Label check>
                    <Input
                      type="radio"
                      value={availableDate}
                      checked={availableDate === pickupDate}
                      onChange={(e) => setPickupDate(e.target.value)}
                    />{' '}
                    {availableDate}
                  </Label>
                </FormGroup>
              ))}
            </div>
            <div className="m-t-1">
              <FormGroup>
                <Label className="font-weight-bold">Order {pickupOrDeliveryText} Time</Label>
                <select className="form-control" {...bindPickupTime}>
                  {Object.entries(pickupTimes).map(([value, text]) => (
                    <option value={value} key={value}>{text.length < 8 ? `0${text}` : text}</option>
                  ))}
                </select>
              </FormGroup>
            </div>
            <div className="list m-b-1 m-t-1">
              <Label className="font-weight-bold">Do you want Home Delivery?</Label>
              <FormGroup check disabled>
                <Label check>
                  <Input
                    type="radio"
                    value="0"
                    checked={isDelivery === '0'}
                    onChange={(e) => switchDeliveryMethods(e.target.value)}
                    disabled
                  />{' '}
                  No
                </Label>
              </FormGroup>

              <FormGroup check disabled>
                <Label check>
                  <Input
                    type="radio"
                    value="1"
                    checked={isDelivery === '1'}
                    onChange={(e) => switchDeliveryMethods(e.target.value)}
                    disabled
                  />{' '}
                  Yes
                </Label>
              </FormGroup>
            </div>
            {(isDelivery === '1') ? deliveryItemBlocks : pickupItemBlocks}
            <FormGroup className="m-b-1_5" check>
              <Label check>
                <Input
                  type="checkbox"
                  value="1"
                  checked={sendTextConfirmation === '1'}
                  onChange={(e) => setSendTextConfirmation(e.target.checked ? '1' : '0')}
                />{' '}
                Please send me text confirmation of my order
              </Label>
            </FormGroup>
            <Button block color="primary" className="order-save-btn" onClick={clickedOnSave}>
              <div className="icon">
                <i className="fas fa-check-double" />
              </div>
              Submit Order
            </Button>
            <FormText color="muted">
              By submitting this form you agree that your WIC benefits will be used to cover the cost of
              the products you have ordered.
            </FormText>
          </Form>
        ) : (
          <div className="m-b-1">You have not added any items to your cart yet.</div>
        )}
        <CancelButton className="m-t-1" />
      </div>
    );
  } else {
    content = (
      <Alert color="danger">There are no available pickup times at the store you selected for today or tomorrow. Please go back and select a different location.</Alert>
    );
  }

  return (
    <div className="OrderInfo">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container">
          {content}
        </div>
      </div>
    </div>
  );
}

OrderInfo.propTypes = {};

OrderInfo.defaultProps = {};

export default OrderInfo;
