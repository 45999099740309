import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'reactstrap';

import './MainNavbar.scss';
import { useDispatch } from 'react-redux';
import { BackButton } from '../BackButton';
import { ProfileArea } from '../ProfileArea';
import { AuthDataOperations } from '../../state/ducks/AuthData';

function MainNavbar(props) {
  const { showBackButton } = props;

  const dispatch = useDispatch();

  const isMember = localStorage.getItem('session') === 'member';
  const goHome = () => dispatch(AuthDataOperations.authOut()).then(() => document.location.assign('/'));

  return (
    <Navbar color="dark" dark expand="md" className="MainNavbar">
      {showBackButton && (
        <BackButton />
      )}
      <div className="brand-centered">
        <NavbarBrand onClick={goHome}>
          <img src="/img/app-logo.png" alt="Prime Time Nutrition" />
        </NavbarBrand>
      </div>
      {isMember && (
        <ProfileArea />
      )}
    </Navbar>
  );
}

MainNavbar.propTypes = {
  showBackButton: PropTypes.bool,
};

MainNavbar.defaultProps = {
  showBackButton: true,
};

export default MainNavbar;
