import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { MainNavbar, StoresList, StoresMap, StoreSearchForm } from '../../components';
import { useInput } from '../../hooks/useInput';
import { usePosition } from '../../hooks/usePosition';
import { AuthDataOperations, AuthDataSelectors } from '../../state/ducks/AuthData';
import { StoresDataOperations, StoresDataSelectors } from '../../state/ducks/StoresData';

import './Stores.scss';

function Stores() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isStoresReady = useSelector((state) => StoresDataSelectors.isReady(state));

  const lastQuery = useSelector((state) => state.StoresData.lastQuery);
  const session = useSelector((state) => AuthDataSelectors.userData(state));
  const stores = useSelector((state) => state.StoresData.stores);

  const [activeTab, setActiveTab] = useState('list');
  const { value: searchQuery, bind: bindSearchQuery } = useInput(lastQuery.searchQuery);
  const { value: range, bind: bindRange } = useInput(lastQuery.range);
  const { lat, lng } = usePosition();

  const haveStores = (stores.length > 0);

  useEffect(() => {
    if (session !== null && Object.keys(session).length > 0) {
      dispatch(AuthDataOperations.authOut()).then();
    }
  }, [dispatch, session]);

  useEffect(() => {
    dispatch(StoresDataOperations.fetchStoresIfNeeded(lat, lng, searchQuery, range));
  }, [dispatch, lat, lng, range, searchQuery]);

  /**
   * Functions to Go to Stores Details Page.
   *
   * @param index Selected Store from List
   */
  const goToStoreDetails = useCallback((index) => {
    // Now add our store data
    const storeData = stores[index];
    window.localStorage.setItem('selectedStore', JSON.stringify(storeData));
    dispatch(StoresDataOperations.selectStore(storeData));
    navigate('/store-details');
  }, [dispatch, navigate, stores]);

  /**
   * Handle the search form submit action.
   *
   * @param event
   */
  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    dispatch(StoresDataOperations.fetchStoresIfNeeded(lat, lng, searchQuery, range));
  }, [dispatch, lat, lng, range, searchQuery]);

  /**
   * Handle switching tabs.
   *
   * @param tab
   */
  const switchTab = useCallback((tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }, [activeTab]);

  return (
    <div className="Stores">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          <StoreSearchForm bindSearchQuery={bindSearchQuery} bindRange={bindRange} range={range} searchQuery={searchQuery} onSubmit={handleSubmit} />

          {(isStoresReady) ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {haveStores ? (
                <div>
                  <Nav pills fill>
                    <NavItem>
                      <NavLink
                        className={classNames({ active: activeTab === 'map' })}
                        onClick={() => { switchTab('map'); }}
                      >
                        Map
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({ active: activeTab === 'list' })}
                        onClick={() => { switchTab('list'); }}
                      >
                        List
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="map">
                      <div className="list">
                        <div className="map-wrapper">
                          <StoresMap defaultCenter={{ lat, lng }} stores={stores} onClickMarker={goToStoreDetails} />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="list">
                      <StoresList stores={stores} onClickStore={goToStoreDetails} />
                    </TabPane>
                  </TabContent>
                </div>
              ) : (
                <div className="m-t-2">No stores were found within range of the requested zip code.</div>
              )}
            </>
          ) : (
            <span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Stores;
