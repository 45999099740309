import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './ItemCategoriesList.scss';
import { ItemCategory } from '../ItemCategory';

import { ItemCategoriesDataOperations, ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';

function ItemCategoriesList({ categories, onProductClick }) {
  const dispatch = useDispatch();

  const selectedCategory = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedCategory'));

  const selectCategory = (uniqKey) => {
    if (uniqKey === selectedCategory) {
      dispatch(ItemCategoriesDataOperations.selectCategory(null));
    } else {
      dispatch(ItemCategoriesDataOperations.selectCategory(uniqKey));
    }
  };

  const itemCats = [];

  Object.entries(categories).forEach(([, group]) => {
    const { Cat, DecrSP, image_url, products, SubCat, UOMSP } = group;
    const uniqKey = `${Cat}-${SubCat}`;

    itemCats.push(
      <ItemCategory
        key={Cat}
        catId={Cat}
        subCatId={SubCat}
        description={DecrSP}
        imageUrl={image_url}
        isOpen={(uniqKey === selectedCategory)}
        onProductClick={onProductClick}
        onToggleClick={() => selectCategory(uniqKey)}
        products={products}
        uom={UOMSP}
      />,
    );
  });

  return (
    <div className="ItemCategoriesList">
      {itemCats}
    </div>
  );
}

ItemCategoriesList.propTypes = {
  onProductClick: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.object),
};

ItemCategoriesList.defaultProps = {
  onProductClick: null,
  categories: [],
};

export default ItemCategoriesList;
