import { parseCommaFloat } from './helpers';

export const calculateBenefitsByCategory = (cartProducts, benefits) => {
  if (benefits == null) {
    return {};
  }

  const benefitsByCategory = {};
  const currentDate = new Date((new Date()).toISOString().substring(0, 10));
  const validBenefits = benefits.filter((benefitGroup) => benefitGroup?.foodBenefitBalance != null && new Date(benefitGroup.beginBenefitDate) <= currentDate && new Date(benefitGroup.endBenefitDate) > currentDate);

  validBenefits.forEach((benefitGroup) => {
    benefitGroup.foodBenefitBalance.forEach((benefit) => {
      const { benefitQuantityAvailable, categoryCode, subcategoryCode } = benefit;
      const catKey = `cat-${categoryCode}`;
      const subCatKey = `sub-${subcategoryCode}`;

      if (benefitsByCategory[catKey] == null) {
        benefitsByCategory[catKey] = {};
      }

      const currAvailable = benefitsByCategory[catKey][subCatKey]?.available || 0;
      const currRemaining = benefitsByCategory[catKey][subCatKey]?.remaining || 0;

      benefitsByCategory[catKey][subCatKey] = {
        available: benefitQuantityAvailable + currAvailable,
        used: 0,
        remaining: benefitQuantityAvailable + currRemaining,
      };
    });
  });

  cartProducts.forEach((item) => {
    const { bftqty, cart_quantity, catid, catUOM, price, subid } = item;

    const benefitUsedByProduct = (catUOM === '$$$') ? price : parseCommaFloat(bftqty);
    const benefitUsedForSelectedQty = (cart_quantity || 0) * benefitUsedByProduct;

    benefitsByCategory[`cat-${catid}`][`sub-${subid}`].used += benefitUsedForSelectedQty;
    benefitsByCategory[`cat-${catid}`][`sub-${subid}`].remaining -= benefitUsedForSelectedQty;
  });

  return benefitsByCategory;
};

export const calculateCartTotalForCategory = (catId, subCatId, selectedProducts) => {
  let catCartBftQty = 0;
  let catCartPrice = 0;
  let catCartQty = 0;

  // Look for product already being in the cart
  if (selectedProducts != null && selectedProducts.products != null) {
    selectedProducts.products.forEach((product) => {
      const { bftqty: bftQty, cart_quantity, catid, price, subid } = product;

      if (!(catid === catId && subid === subCatId)) {
        return;
      }

      catCartBftQty += (cart_quantity * parseCommaFloat(bftQty));
      catCartPrice += (cart_quantity * parseCommaFloat(price));
      catCartQty += cart_quantity;
    });
  }

  return {
    catCartBftQty: catCartBftQty.toFixed(2),
    catCartPrice,
    catCartQty,
  };
};
