import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import './CartCategory.scss';
import { CartProduct } from '../CartProduct';
import { CartQty } from '../CartQty';
import { WumeiDataSelectors } from '../../state/ducks/WumeiData';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { calculateCartTotalForCategory, parseCommaFloat, sortByInCartThenSeq } from '../../utils';

function CartCategory(props) {
  const { catId, description, imageUrl, products, subCatId, uom } = props;
  const cartQtyByProduct = useSelector((state) => ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));
  const selectedProducts = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedProducts'));
  const items = [];

  // Put our products in order based on sequence or ordered amount
  const productsInOrder = Object.entries(products).sort(sortByInCartThenSeq(cartQtyByProduct));
  const { catCartBftQty, catCartPrice, catCartQty } = calculateCartTotalForCategory(catId, subCatId, selectedProducts);

  if (productsInOrder.length > 0) {
    productsInOrder.forEach(([, item]) => {
      const { bftqty: bftQty, descript: itemDescription, image_url: itemImageURL, prodkey, subid } = item;
      const cartQty = parseInt(cartQtyByProduct?.[catId]?.[subid]?.[prodkey]) || 0;
      const itemPrice = parseCommaFloat(item.price);
      const cartPrice = cartQty * itemPrice;

      if (cartQty > 0) {
        items.push(
          <CartProduct
            key={prodkey}
            cartPrice={cartPrice}
            cartQty={cartQty}
            size={bftQty}
            uom={uom}
            description={itemDescription}
            imageUrl={itemImageURL || imageUrl}
          />,
        );
      }
    });
  }

  // If there are no items for this category then we don't show it
  if (catCartQty === 0) {
    return;
  }

  return (
    <div role="link" className="CartCategory">
      <div className="divider">
        <div className="row align-items-center">
          <div className="col-9 col-sm-7 col-md-6 col-lg-7 description">
            <span className="d-block text-truncate">
              {description}
            </span>
          </div>
          <CartQty
            benefitsRemaining={benefitsByCategory?.[`cat-${catId}`]?.[`sub-${subCatId}`]?.remaining}
            cartBftQty={catCartBftQty}
            cartQty={catCartQty}
            className="col-3 col-sm-5 col-md-6 col-lg-5 ms-auto text-right"
            price={catCartPrice}
            uom={uom}
          />
        </div>
      </div>
      <div className="list sub-list">
        {items}
      </div>
    </div>
  );
}

CartCategory.propTypes = {
  catId: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  subCatId: PropTypes.any,
  uom: PropTypes.string,
};

CartCategory.defaultProps = {
  catId: null,
  description: null,
  imageUrl: null,
  products: [],
  subCatId: null,
  uom: null,
};

export default CartCategory;
