import React, { useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { CancelButton, ItemCategoriesList, MainNavbar } from '../../components';

import './ItemCategories.scss';
import { ItemCategoriesDataOperations, ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { StoresDataSelectors } from '../../state/ducks/StoresData';
import { AuthDataSelectors } from '../../state/ducks/AuthData';
import { WumeiDataOperations, WumeiDataSelectors } from '../../state/ducks/WumeiData';

function ItemCategories() {
  const dispatch = useDispatch();

  const benefits = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefits'));
  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const isAuthReady = useSelector((state) => AuthDataSelectors.isReady(state));
  const isReady = useSelector((state) => ItemCategoriesDataSelectors.isReady(state));
  const isWumeiReady = useSelector((state) => WumeiDataSelectors.isReady(state));
  const selectedStore = useSelector((state) => StoresDataSelectors.returnItemFromState(state, 'selectedStore'));
  const session = useSelector((state) => AuthDataSelectors.userData(state));
  const totalCartQty = useSelector((state) => ItemCategoriesDataSelectors.returnTotalCartCountFromState(state));

  const haveItemCategories = (categories.length > 0);
  const haveItemsInTheCart = (totalCartQty > 0);
  const navigate = useNavigate();

  // Make sure we have the data needed for this page
  useEffect(() => {
    if (selectedStore == null) {
      navigate('/', { replace: true });
    } else {
      window.localStorage.removeItem('selectedStore');
    }
  }, [categories, navigate, selectedStore]);

  /**
   * The hook tracks the user's session and, if there is one and no
   * data on the user's benefits, executes a request for benefits.
   *
   */
  useEffect(() => {
    if (selectedStore && (session !== null && Object.keys(session).length > 0) && isWumeiReady && benefits === null) {
      dispatch(WumeiDataOperations.receiveBenefits(selectedStore.store_no));
    }
  }, [benefits, dispatch, isWumeiReady, selectedStore, session]);

  useEffect(() => {
    if (selectedStore && benefits !== null) {
      dispatch(ItemCategoriesDataOperations.fetchAllCategoriesIfNeeded(selectedStore.store_no, benefits));
    }
  }, [benefits, dispatch, selectedStore]);

  const goToItemDetails = (catId, subCatId, productId) => {
    navigate(`/item-details/${catId}/${subCatId}/${productId}`);
  };

  return (
    <div className="ItemCategories">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer footer-spacer">
        <div className="container-fluid">
          {isReady && isAuthReady && isWumeiReady ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {haveItemCategories ? (
                <>
                  <p>Please select items for your order that are available from your {selectedStore.state} WIC Benefits</p>
                  <ItemCategoriesList categories={categories} onProductClick={goToItemDetails} />
                </>
              ) : (
                <div className="m-t-2">There are no categories to purchase from based on your selected store and available benefits.</div>
              )}
            </>
          ) : (
            <span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>
          )}
        </div>
        <footer>
          <div className="toolbar container-fluid">
            <Row className="gx-1">
              <Col xs="4">
                <CancelButton />
              </Col>
              <Col xs="8" className="text-right">
                {haveItemsInTheCart && (
                  <Button color="primary" onClick={() => navigate('/cart-summary')}>
                    <div className="icon">
                      <i className="fas fa-check" />
                    </div>
                    Done with Products
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ItemCategories;
