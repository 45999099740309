import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import './ItemCategory.scss';
import { CartQty } from '../CartQty';
import { Product } from '../Product';
import { WumeiDataSelectors } from '../../state/ducks/WumeiData';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { calculateCartTotalForCategory, sortByInCartThenSeq } from '../../utils';

function ItemCategory(props) {
  const { catId, description, imageUrl, isOpen, onProductClick, onToggleClick, products, subCatId, uom } = props;
  const cartQtyByProduct = useSelector((state) => ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));
  const selectedProducts = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedProducts'));
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const items = [];

  // If there are no benefits for this category then we don't show it
  if (benefitsByCategory == null || Object.keys(benefitsByCategory).length === 0 || benefitsByCategory[`cat-${catId}`] == null) {
    return;
  }

  // Put our products in order based on sequence or ordered amount
  const productsInOrder = Object.entries(products).sort(sortByInCartThenSeq(cartQtyByProduct));
  const { catCartBftQty, catCartPrice, catCartQty } = calculateCartTotalForCategory(catId, subCatId, selectedProducts);

  productsInOrder.forEach(([_, item]) => {
    const { bftqty: bftQty, descript: itemDescription, fiseq, image_url: itemImageURL, prodkey, subid } = item;
    const benefitsAvailable = benefitsByCategory[`cat-${catId}`]?.[`sub-${subid}`]?.available || 0;

    // If there are no benefits or inventory for this product then we do not show it
    if (benefitsAvailable <= 0 || (fiseq != null && fiseq.length > 0 && parseInt(fiseq, 10) <= 0)) {
      return;
    }

    const cartQty = parseInt(cartQtyByProduct?.[catId]?.[subid]?.[prodkey]) || 0;

    items.push(
      <Product
        key={prodkey}
        cartQuantity={cartQty}
        description={itemDescription}
        size={bftQty}
        uom={uom}
        imageUrl={itemImageURL || imageUrl}
        onProductClick={() => onProductClick(catId, subCatId, prodkey)}
      />,
    );
  });

  return (
    <div role="link" className="ItemCategory">
      <div className="divider" onClick={onToggleClick} aria-hidden="true">
        <div className="row align-items-center">
          <div className="col-9 col-sm-7 col-md-6 col-lg-7">
            <div className="d-flex">
              <div className="toggleIcon icon">
                <i className={classNames('fas', { 'fa-caret-down': !isOpen, 'fa-caret-up': isOpen })} />
              </div>
              <div className="description">
                <span className="d-block text-truncate">
                  {description}
                </span>
              </div>
            </div>
          </div>
          <CartQty
            benefitsRemaining={benefitsByCategory?.[`cat-${catId}`]?.[`sub-${subCatId}`]?.remaining}
            cartBftQty={catCartBftQty}
            cartQty={catCartQty}
            className="col-3 col-sm-5 col-md-6 col-lg-5 ms-auto text-right"
            price={catCartPrice}
            uom={uom}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="list sub-list">
          {items.length === 0 ? (
            <div className="row">
              <div className="col">There are no products available for this category.</div>
            </div>
          ) : items}
        </div>
      </Collapse>
    </div>
  );
}

ItemCategory.propTypes = {
  catId: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  onProductClick: PropTypes.func,
  onToggleClick: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.object),
  subCatId: PropTypes.any,
  uom: PropTypes.string,
};

ItemCategory.defaultProps = {
  catId: null,
  description: null,
  imageUrl: null,
  isOpen: false,
  onProductClick: null,
  onToggleClick: null,
  products: [],
  subCatId: null,
  uom: null,
};

export default ItemCategory;
