import React, { useEffect, useState, useCallback } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-phone-input-2/lib/style.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { CopyrightNotice, PrivacyPolicyModal } from '../../components';
import { AuthDataOperations, AuthDataSelectors } from '../../state/ducks/AuthData';
import { StoresDataOperations, StoresDataSelectors } from '../../state/ducks/StoresData';
import { useInput } from '../../hooks/useInput';

import './CdpCallback.scss';

const MySwal = withReactContent(Swal);

function CdpCallback() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isReady = useSelector((state) => AuthDataSelectors.isReady(state));
  const selectedStore = useSelector((state) => StoresDataSelectors.returnItemFromState(state, 'selectedStore'));
  const user = useSelector((state) => AuthDataSelectors.userData(state));
  const codeVerifier = window.localStorage.getItem('codeVerifier');

  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const scope = params.get('scope');
  const error = params.get('error');

  const [isValid, setIsValid] = useState(false);
  const { value: firstname, bind: bindFirstname, setValue: setFirstname } = useInput('');
  const { value: lastname, bind: bindLastname, setValue: setLastname } = useInput('');
  const { value: phoneNumber, setValue: setPhoneNumber } = useInput('');
  const { value: emailAddress, bind: bindEmailAddress, setValue: setEmailAddress } = useInput('');
  const { value: zipcode, bind: bindZipcode, setValue: setZipcode } = useInput('');

  /* Load the store data the user selected previously from local storage. */
  useEffect(() => {
    const storeData = JSON.parse(window.localStorage.getItem('selectedStore'));
    dispatch(StoresDataOperations.selectStore(storeData));
  }, [dispatch]);

  /**
   * The hook tracks the query parameters of the page. If the 'code'
   * parameter is present, it initiates the continuation of the
   * authorization process. If the 'error' parameter is present,
   * displays the corresponding error.
   *
   */
  useEffect(() => {
    if (selectedStore && code !== null && codeVerifier != null) {
      dispatch(AuthDataOperations.authCallback(selectedStore.store_no, code, codeVerifier))
        .catch(() => {
          MySwal.fire({
            allowOutsideClick: false, icon: 'error', text: 'Authorization failed, please try again. If this error persists please contact us.', willClose: () => navigate('/'),
          });
        });
    }

    if (error !== null) {
      MySwal.fire({
        allowOutsideClick: false, icon: 'error', text: 'Sorry, but in order to take advantage of NFI capabilities, you need to grant the required permissions.', willClose: () => navigate('/'),
      });
    }
  }, [code, codeVerifier, error, dispatch, navigate, selectedStore]);

  /**
   * The hook tracks an object with user data from the store. The
   * user profile data form is autofilled when it is received. If
   * the profile completion flag is present, the user is redirected
   * to the stores page. Additionally, if the user has not given
   * permission to update access tokens, a notification will be displayed.
   *
   */
  useEffect(() => {
    if (!user) {
      return;
    }

    setFirstname(user.firstname || '');
    setLastname(user.lastname || '');
    setPhoneNumber(user.phone_number || '');
    setEmailAddress(user.email_address || '');
    setZipcode(user.zipcode || '');

    if (!scope.includes('offline_access')) {
      const alert = {
        allowOutsideClick: false, icon: 'warning', text: 'You have chosen to not provide us with the Offline Access permission. Your session will last for 1 hour, after this time you will need to log back in.',
      };

      if (user.completed) {
        alert.willClose = () => navigate('/item-categories');
      }

      MySwal.fire(alert);
    } else if (user.completed) {
      navigate('/item-categories');
    }
  }, [user, scope, navigate, setFirstname, setLastname, setPhoneNumber, setEmailAddress, setZipcode]);

  /**
   * The hook tracks changes in the fields of the form with the user's
   * data in order to validate it.
   *
   */
  useEffect(() => {
    setIsValid(firstname.length !== 0 && lastname.length !== 0 && phoneNumber.length === 11 && zipcode.length >= 5);
  }, [firstname, lastname, phoneNumber, zipcode]);

  /**
   * Handle the profile form submit action. Displays a notification
   * of successful authorization together with full profile creation
   * or the corresponding error.
   *
   * @param event
   */
  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      firstname, lastname, phone_number: phoneNumber.includes('+') ? formatPhoneNumberIntl(phoneNumber) : formatPhoneNumberIntl(`+${phoneNumber}`), email_address: emailAddress, zipcode,
    };

    dispatch(AuthDataOperations.authFinish(data))
      .then(() => {
        MySwal.fire({
          allowOutsideClick: false,
          icon: 'success',
          text: 'Your NFI account has been successfully created.',
          timer: 5000, // 5s
          willClose: () => navigate('/item-categories'),
        });
      })
      .catch(() => {
        MySwal.fire({
          allowOutsideClick: false, icon: 'error', text: 'Authorization failed, please try again. If this error persists please contact us.',
        });
      });
  }, [dispatch, navigate, firstname, lastname, phoneNumber, emailAddress, zipcode]);

  return (
    <div className="Callback">
      <header>
        <div className="logo-wrapper">
          <img src="/img/app-logo.png" alt="Prime Time Nutrition" />
        </div>
      </header>

      <PrivacyPolicyModal />

      {!isReady ? (
        <div className="safeAreaWrapper">
          <span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>
          <p className="text-center fa-2x">Please, wait.</p>
        </div>
      ) : (!user?.completed && (
        <>
          <p className="text-center">Please make sure your data is correct and up-to-date.<br />You can edit the data or add missing ones.</p>

          <Form onSubmit={handleSubmit}>

            <FormGroup>
              <Label>First name*:</Label>
              <Input required type="text" {...bindFirstname} />
            </FormGroup>

            <FormGroup>
              <Label>Last name*:</Label>
              <Input required type="text" {...bindLastname} />
            </FormGroup>

            <FormGroup>
              <Label>Phone No*:</Label>
              <PhoneInput
                required
                country="us"
                onlyCountries={['us']}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </FormGroup>

            <FormGroup>
              <Label>Email address:</Label>
              <Input type="email" {...bindEmailAddress} />
            </FormGroup>

            <FormGroup>
              <Label>Zip code*:</Label>
              <Input required type="text" {...bindZipcode} />
            </FormGroup>

            <Button type="submit" block color="primary" disabled={!isValid}>
              <div className="icon">
                <i className="fas fa-check-double" />
              </div>
              Submit
            </Button>

          </Form>
        </>
      ))}

      <CopyrightNotice />
    </div>
  );
}

export default CdpCallback;
