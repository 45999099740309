import { returnItemFromState } from './selectors';
import * as types from './types';
import { actions, authagent } from '../../utils';
import { calculateBenefitsByCategory } from '../../../utils';
import { BASE_API_URL } from '../../../constants';

// Create our shorthand action dispatches
export const receiveRequest = (scope, data) => actions.returnDispatchSetRequest(types.RECEIVE_WUMEI_REQUEST, scope, data);
export const setFetching = (scope, fetching) => actions.returnDispatchSetFetching(types.SET_WUMEI_FETCHING, scope, fetching);

// Define any functions we need to run our dispatches

/**
 * Executes a request to get the benefits data for the user.
 *
 * @param storeNo
 * @returns {Promise<*|*[]>}
 */
async function getBenefits(storeNo) {
  const res = await authagent.get(`${BASE_API_URL}benefits`).query({ store: storeNo })
    .withCredentials()
    .set('Accept', 'application/json');

  return res.body?.result;
}

// Now create our actions which require us to dispatch back a fetch

/**
 * Initiates a request for user benefits in the CDP system, entering
 * the received data in the corresponding store.
 *
 * @param storeNo
 * @returns {Function}
 */
export const receiveBenefits = (storeNo) => (dispatch) => {
  dispatch(setFetching('wumei', true));

  getBenefits(storeNo)
    .then((response) => {
      dispatch(receiveRequest('benefits', response));
      dispatch(receiveRequest('benefitsByCategory', calculateBenefitsByCategory([], response)));
      dispatch(setFetching('wumei', false));
    })
    .catch((error) => {
      console.error('Wumei benefits error:', error);
    });
};

export const updateBenefitsByCategory = (cartProducts) => (dispatch, getState) => {
  dispatch(setFetching('wumei', true));

  const benefits = returnItemFromState(getState(), 'benefits');

  dispatch(receiveRequest('benefitsByCategory', calculateBenefitsByCategory(cartProducts, benefits)));
  dispatch(setFetching('wumei', false));
};

export const clearBenefits = () => (dispatch) => {
  dispatch(setFetching('wumei', true));
  dispatch(receiveRequest('benefits', null));
  dispatch(receiveRequest('benefitsByCategory', null));
  dispatch(setFetching('wumei', false));
};
