import { parseCommaFloat } from '../../../utils';

/**
 * Return the item controls section of the state.
 *
 * @param state
 */
export function returnMySliceOfState(state) {
  return state.ItemCategoriesData;
}

/**
 * Check if we are fetching any itemCategories in the item.
 *
 * @param state
 * @returns boolean
 */
export function isFetching(state) {
  const curState = returnMySliceOfState(state);

  return (curState.categories_isFetching);
}

/**
 * Check if we are loading any itemCategories in the item.
 *
 * @param state
 * @returns boolean
 */
export function isLoading(state) {
  // const curState = returnMySliceOfState(state);

  // return ( curState.categories_isLoading);
  return false;
}

/**
 * Check if we are ready to display.
 *
 * @param state
 * @returns boolean
 */
export function isReady(state) {
  return (!isFetching(state) && !isLoading(state));
}

/**
 * Return an item from our state object.
 *
 * @param state
 * @param item
 * @returns {*}
 */
export function returnItemFromState(state, item) {
  const curState = returnMySliceOfState(state);
  return curState[item];
}

/**
 * Get the total cart quantity for the selected products.
 *
 * @param state
 * @returns {number}
 */
export function returnTotalCartCountFromState(state) {
  let totalCartQty = 0;
  const selectedProducts = returnItemFromState(state, 'selectedProducts');

  if (selectedProducts != null && selectedProducts.products != null) {
    // Look for product already being in the cart
    selectedProducts.products.forEach((product, index) => {
      totalCartQty += Number(product.cart_quantity);
    });
  }

  return totalCartQty;
}

/**
 * Get the total cart quantity for the selected products.
 *
 * @param state
 * @returns {number}
 */
export function returnCartQtyByProductFromState(state) {
  const cartQtyByProduct = {};
  const selectedProducts = returnItemFromState(state, 'selectedProducts');

  if (selectedProducts != null && selectedProducts.products != null) {
    // Look for product already being in the cart
    selectedProducts.products.forEach((product, index) => {
      const { cart_quantity, catid, prodkey, subid } = product;

      if (cartQtyByProduct[catid] === undefined) {
        cartQtyByProduct[catid] = {};
      }

      if (cartQtyByProduct[catid][subid] === undefined) {
        cartQtyByProduct[catid][subid] = {};
      }

      cartQtyByProduct[catid][subid][prodkey] = Number(cart_quantity);
    });
  }

  return cartQtyByProduct;
}

/**
 * Get the total cart price for the selected products.
 *
 * @param state
 * @returns {number}
 */
export function returnTotalCartPriceFromState(state) {
  return returnItemFromState(state, 'selectedProducts')?.products?.reduceRight(
    (price, product) => price + (parseCommaFloat(product.cart_quantity) * parseCommaFloat(product.price)),
    0,
  );
}

/**
 * Get the total cart price for the selected products.
 *
 * @param state
 * @returns {number}
 */
export function returnTotalCartPriceWithBenefitsFromState(state) {
  return returnItemFromState(state, 'selectedProducts')?.products?.reduceRight(
    (price, product) => (price += product.benefit < parseCommaFloat(product.cart_quantity) // eslint-disable-line no-param-reassign
      ? (parseCommaFloat(product.cart_quantity) - product.benefit) * parseCommaFloat(product.price)
      : 0),
    0,
  );
}
