import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment-timezone';

import './ThankYou.scss';

import { MainNavbar } from '../../components';
import { AuthDataOperations } from '../../state/ducks/AuthData';
import { ItemCategoriesDataOperations } from '../../state/ducks/ItemCategoriesData';
import { OrdersDataSelectors } from '../../state/ducks/OrdersData';
import { StoresDataSelectors } from '../../state/ducks/StoresData';
import { WumeiDataOperations } from '../../state/ducks/WumeiData';

function ThankYou() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectProducts = (product) => dispatch(ItemCategoriesDataOperations.selectProducts(product));
  const updateBenefitsByCategory = (cartProducts) => dispatch(WumeiDataOperations.updateBenefitsByCategory(cartProducts));

  const order = useSelector((state) => OrdersDataSelectors.returnItemFromState(state, 'order'));
  const selectedStore = useSelector((state) => StoresDataSelectors.returnItemFromState(state, 'selectedStore'));

  // Reset the user's benefits since they changed
  useEffect(() => {
    dispatch(WumeiDataOperations.clearBenefits());
  }, [dispatch]);

  const { store_no = '', phone_number = '', valid_timezones: storeTimezone = 'America/Los_Angeles' } = selectedStore || {};
  const { phone_number: orderPhoneNumber = '', pickup_at = '' } = order;
  const confirmationNumber = orderPhoneNumber.slice(orderPhoneNumber.length - 4);
  const pickupDateAndTimeMoment = Moment(pickup_at).tz(storeTimezone);
  const pickupDateAndTime = `${pickupDateAndTimeMoment.format('dddd MMMM DD, YYYY')} at ${pickupDateAndTimeMoment.format('h:mm A')}`;

  const homeOnClick = () => {
    // Clear our cart products
    selectProducts([]);

    // Recalculate the benefits
    updateBenefitsByCategory([]);

    dispatch(AuthDataOperations.authOut()).then(() => navigate('/', { replace: true }));
  };

  return (
    <div className="ThankYou">
      <MainNavbar showBackButton={false} />
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          <h1>Thank You!</h1>
          <div>
            <p>Thank you for your order, your products will be waiting for you in store.</p>
            <h3>Your Confirmation Number is {confirmationNumber}</h3>
            <p>Please Arrive To The Store And Bring Your WIC CARD</p>
            <p>{pickupDateAndTime}</p>
            <br />
            <p>If you need to cancel or change your order please call</p>
            <p>Primetime Nutrition Store #{store_no} - {phone_number}</p>
            <br />
            <p>Please provide your confirmation number and vehicle description for quicker service.</p>
          </div>

          <Button block onClick={homeOnClick}>
            <div className="icon">
              <i className="fas fa-home" />
            </div>
            Home
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
