import React, { useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MainNavbar } from '../../components';
import './StoreDetails.scss';
import { StoresDataSelectors } from '../../state/ducks/StoresData';

function StoreDetails() {
  const navigate = useNavigate();
  const selectedStore = useSelector((state) => StoresDataSelectors.returnItemFromState(state, 'selectedStore'));

  // Make sure we have the data needed for this page
  useEffect(() => {
    if (selectedStore == null) {
      navigate('/', { replace: true });
    }
  }, [navigate, selectedStore]);

  const clickedOnCall = () => {
    // console.log('StoreDetails::Clicked on Call....');
    const phoneNumber = selectedStore.phone_number.replace(/[^0-9]/g, '');
    window.location.href = `tel:${phoneNumber}`;
    // this.showAlert('Alert!',' Do you want to Call?');
  };

  const { display_name, description, address, city, state: st, zip, phone_number } = selectedStore || {};

  return (
    <div className="StoreDetails">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer footer-spacer">
        <div className="container">
          <h2 className="store-no">{display_name || 'Store No'}</h2>

          <div className="store-address-wrapper">
            {address || 'Street'}<br />
            {city || 'City'}, {st || 'ST'} {zip || '0000'}<br />
            <br />
            {phone_number}
          </div>

          <h2 className="store-hours">Store Hours</h2>
          <div className="store-hours-wrapper">
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>

          <div>
            <Row>
              <Col xs="6" className="text-center">
                <Button color="light" onClick={clickedOnCall}>
                  <div className="icon">
                    <i className="fas fa-phone" />
                  </div>
                  Call
                </Button>
              </Col>
              <Col xs="6" className="text-center">
                <Button color="light" onClick={() => navigate('/', { replace: true })}>
                  <div className="icon">
                    <i className="fas fa-home" />
                  </div>
                  Home
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <footer>
          <div className="toolbar">
            <Button block size="lg" color="primary" onClick={() => navigate('/sign-in')}>
              <div className="icon">
                <i className="fas fa-shopping-cart" />
              </div>
              Start Order
            </Button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default StoreDetails;
