import * as types from './types';
import { actions, authagent } from '../../utils';
import { BASE_API_URL } from '../../../constants';

const pkceChallenge = require('pkce-challenge').default;

// Create our shorthand action dispatches
export const receiveRequest = (scope, data) => actions.returnDispatchSetRequest(types.RECEIVE_AUTH_REQUEST, scope, data);
export const setLoading = (scope, loading) => actions.returnDispatchSetLoading(types.SET_AUTH_LOADING, scope, loading);

// Define any functions we need to run our dispatches
/**
 * Executes the initiating authorization request.
 *
 * @param storeNo
 * @param codeChallenge
 *
 * @returns {Promise<*|*[]>}
 */
async function auth(storeNo, codeChallenge) {
  const res = await authagent.get(`${BASE_API_URL}authorization?store=${storeNo}&code_challenge=${codeChallenge}`)
    .withCredentials()
    .set('Accept', 'application/json');

  return res.body?.result;
}

/**
 * Makes a request with the CDP identity server callback data as
 * part of the authorization process continuation.
 *
 * @param storeNo
 * @param code
 * @param codeVerifier
 *
 * @returns {Promise<*|*[]>}
 */
async function cdpCallback(storeNo, code, codeVerifier) {
  const res = await authagent.post(`${BASE_API_URL}authorization`)
    .send({ store: storeNo, code, code_verifier: codeVerifier })
    .withCredentials()
    .set('Accept', 'application/json');

  return res.body?.result;
}

/**
 * Makes a request to update the user profile during the initial
 * authorization.
 *
 * @param userData
 *
 * @returns {Promise<*|*[]>}
 */
async function endAuth(userData) {
  const res = await authagent.put(`${BASE_API_URL}authorization`)
    .send(userData)
    .withCredentials()
    .set('Accept', 'application/json');

  return res.body?.result;
}

/**
 * Performs a request to de-authorize a user.
 *
 * @returns {Promise<*|*[]>}
 */
async function logout() {
  const res = await authagent.delete(`${BASE_API_URL}authorization`)
    .withCredentials()
    .set('Accept', 'application/json');

  return res.body?.result;
}

// Now create our actions which require us to dispatch back a fetch

/**
 * Starts user authorization and redirects to the stores page or
 * the authorization page through the CDP account if successful.
 * The 'checkOnly' parameter assumes checking the current user
 * session and does not initiate redirects, only overwriting the
 * corresponding status in LocalStorage in this case.
 *
 * @param storeNo
 * @param checkOnly boolean
 * @returns {Function}
 */
export const authStart = (storeNo, checkOnly = false) => (dispatch) => new Promise((resolve, reject) => {
  // Mark that we are loading things.
  dispatch(setLoading('auth', true));

  // Generate our PKCE items
  const { code_verifier: codeVerifier, code_challenge: codeChallenge } = pkceChallenge();

  // Save the verifier so we can use it later.
  window.localStorage.setItem('codeVerifier', codeVerifier);

  auth(storeNo, codeChallenge)
    .then((response) => {
      if (!checkOnly) {
        if (typeof response === 'string') {
          dispatch(receiveRequest('userData', null));
          window.localStorage.removeItem('session');

          return document.location.assign(response);
        }

        dispatch(receiveRequest('userData', response));
        window.localStorage.setItem('session', 'member');
      }

      resolve(dispatch(setLoading('auth', false)));
    })
    .catch((error) => {
      console.error('Authorization error:', error);
      reject();
    });
});

/**
 * Sends CDP system callback data and returns user data if successful.
 *
 * @param storeNo
 * @param code
 * @param codeVerifier
 *
 * @returns {function(*): Promise<*|*[]>}
 */
export const authCallback = (storeNo, code, codeVerifier) => (dispatch) => new Promise((resolve, reject) => {
  // Mark that we are loading things.
  dispatch(setLoading('auth', true));

  cdpCallback(storeNo, code, codeVerifier)
    .then((response) => {
      window.localStorage.setItem('session', 'member');
      dispatch(receiveRequest('userData', response));
      resolve(dispatch(setLoading('auth', false)));
      window.localStorage.removeItem('codeVerifier');
    })
    .catch((error) => {
      console.error('Authorization callback error:', error);
      dispatch(setLoading('auth', false));
      reject(error);
    });
});

/**
 * Finishes the initial authorization by updating the user's
 * profile data.
 *
 * @param userData
 *
 * @returns {function(*): Promise<*|*[]>}
 */
export const authFinish = (userData) => (dispatch) => new Promise((resolve, reject) => {
  // Mark that we are loading things.
  dispatch(setLoading('auth', true));

  endAuth(userData)
    .then((response) => {
      dispatch(setLoading('auth', false));
      return typeof response === 'boolean' ? resolve(dispatch(receiveRequest('userData', null))) : reject();
    })
    .catch((error) => {
      console.error('Authorization callback error:', error);
      reject();
    });
});

/**
 * Terminates the current authorization session and redirects to
 * the home page.
 *
 * @returns {function(*): Promise<*|*[]>}
 */
export const authOut = () => (dispatch) => new Promise((resolve, reject) => {
  // Mark that we are loading things.
  dispatch(setLoading('auth', true));

  logout()
    .then(() => {
      window.localStorage.removeItem('session');
      resolve(dispatch(setLoading('auth', false)));
    })
    .catch((error) => {
      console.error('Authorization logout error:', error);
      reject();
    });
});
