import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import './CancelButton.scss';

import { WumeiDataOperations } from '../../state/ducks/WumeiData';
import { ItemCategoriesDataOperations } from '../../state/ducks/ItemCategoriesData';

const MySwal = withReactContent(Swal);

function CancelButton(props) {
  const { className } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectProducts = (product) => dispatch(ItemCategoriesDataOperations.selectProducts(product));
  const updateBenefitsByCategory = (cartProducts) => dispatch(WumeiDataOperations.updateBenefitsByCategory(cartProducts));

  const showSwal = () => {
    MySwal.fire({
      title: 'Cancel Order',
      icon: 'error',
      text: 'Are you sure you want to cancel your order?',
      showCancelButton: true,
      confirmButtonText: 'Yes, Cancel It',
      cancelButtonText: 'No, Keep Working',
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear our cart products
        selectProducts([]);

        // Recalculate the benefits
        updateBenefitsByCategory([]);

        navigate('/', { replace: true });
      }
    });
  };

  return (
    <Button color="danger" className={classNames('cancelButton', className)} onClick={showSwal}>
      <div className="icon">
        <i className="fas fa-times" />
      </div>
      Cancel
    </Button>
  );
}

CancelButton.defaultProps = {
  className: '',
};

CancelButton.propTypes = {
  className: PropTypes.string,
};

export default CancelButton;
