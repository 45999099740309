import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './Product.scss';
import classNames from 'classnames';

function Product(props) {
  const { cartQuantity, description, imageUrl, onProductClick, size, uom } = props;
  const itemAmount = cartQuantity || 0;

  return (
    <FormGroup onClick={onProductClick} className={classNames('Product', 'row', 'mx-0', { hasQty: itemAmount > 0 })}>
      <div className="thumbnail col-3 col-sm-2 text-center">
        <img src={imageUrl} alt="" />
      </div>
      <div className="description col-6 col-sm-6 col-md-5 col-lg-6">
        <span className="d-block text-truncate">
          {description}
          {uom !== '$$$' && (<><br />{size} {uom}</>)}
        </span>
      </div>
      <div className="col-3 col-sm-4 col-md-5 col-lg-4 ms-auto text-right">
        <span className={classNames('d-none', 'd-sm-inline')}>Selected: </span>
        <span className="text-success">
          {itemAmount}
        </span>

        <div className="icon">
          <i className="fas fa-caret-right" />
        </div>
      </div>
    </FormGroup>
  );
}

Product.propTypes = {
  cartQuantity: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  onProductClick: PropTypes.func,
  size: PropTypes.string,
  uom: PropTypes.string,
};

Product.defaultProps = {
  cartQuantity: null,
  description: null,
  imageUrl: null,
  onProductClick: null,
  size: null,
  uom: null,
};

export default Product;
