/**
 * Parses strings as floating-point numbers, processing strings
 * where both a dot and a comma can be used as a separator.
 *
 * @param str string
 * @param locale string
 *
 * @returns {number}
 */
export function parseCommaFloat(str, locale = 'en-US') {
  // Get the thousands and decimal separator characters used in the locale.
  const [, thousandsSeparator, , , , decimalSeparator] = 1111.1.toLocaleString(locale);

  // Remove the thousand place separator, and put a decimal where the decimal separator occurs
  const cleanStr = Array.from(String(str), (c) => {
    if (c === thousandsSeparator) {
      return '';
    }

    if (c === decimalSeparator) {
      return '.';
    }

    return c;
  }).join('');

  // Now it can be parsed
  return parseFloat(cleanStr);
}
