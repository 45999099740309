import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FormGroup, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './ItemDetails.scss';

import { MainNavbar } from '../../components';
import { useInput } from '../../hooks/useInput';
import { parseCommaFloat } from '../../utils';
import { WumeiDataOperations, WumeiDataSelectors } from '../../state/ducks/WumeiData';
import { ItemCategoriesDataOperations, ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';

const MySwal = withReactContent(Swal);

function ItemDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const selectedProducts = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedProducts'));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));

  const selectProducts = (product) => dispatch(ItemCategoriesDataOperations.selectProducts(product));
  const updateBenefitsByCategory = (cartProducts) => dispatch(WumeiDataOperations.updateBenefitsByCategory(cartProducts));

  const { catId, subCatId, productId } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [qtyAvailableFromBenefits, setQtyAvailableFromBenefits] = useState(0);
  const { value: quantity, bind: bindQuantity, setValue: setQuantity } = useInput(1);

  const { bftqty, catUOM, image_url, descript = 'Item Name', price } = selectedItem;
  const totalUOM = (parseCommaFloat(bftqty) * quantity).toFixed(2);
  const total = (parseCommaFloat(price) * quantity).toFixed(2);

  useEffect(() => {
    if (categories.length === 0) {
      navigate('/', { replace: true });
    }
  }, [categories, navigate]);

  useEffect(() => {
    // See if the product is in the selectedProducts set
    let productFoundInSelectedProducts = null;

    if (selectedProducts != null && selectedProducts.products != null) {
      const { products = [] } = selectedProducts;

      // Look for product already being in the cart
      products.forEach((product) => {
        const { catid, prodkey, subid } = product;

        if (productId.toString() === prodkey.toString() && catId.toString() === catid.toString() && subCatId.toString() === subid.toString()) {
          productFoundInSelectedProducts = product;
        }
      });
    }

    if (productFoundInSelectedProducts != null) {
      // We've added this to the cart before so lets access it
      setSelectedItem(productFoundInSelectedProducts);
      setQuantity(parseInt(productFoundInSelectedProducts.cart_quantity));
    } else {
      // We need to find it in the main categories object
      Object.entries(categories).forEach(([, cat]) => {
        const { Cat, products, SubCat } = cat;

        if (catId.toString() === Cat.toString() && subCatId.toString() === SubCat.toString()) {
          for (const product of products) {
            const { prodkey } = product;

            if (productId.toString() === prodkey.toString()) {
              setSelectedItem(product);
              setQuantity(1);
            }
          }
        }
      });
    }
  }, [categories, catId, productId, selectedProducts, setQuantity, subCatId]);

  /**
   * The hook tracks data on the user's benefits, filtering and forming
   * the appropriate balance of the product available for the benefit
   * according to the category and subcategory of it.
   *
   */
  useEffect(() => {
    if (Object.keys(selectedItem).length > 0) {
      const { bftqty: itemBftQty, cart_quantity, catid, price: itemPrice, subid } = selectedItem;
      const bftQty = parseCommaFloat(itemBftQty);

      const benefitsRemaining = benefitsByCategory[`cat-${catid}`]?.[`sub-${subid}`]?.remaining || 0;
      const benefitUsedByProduct = (catUOM === '$$$') ? itemPrice : bftQty;
      const benefitUsedForSelectedQty = (cart_quantity || 0) * benefitUsedByProduct;
      const benefitsRemainingWithCurrentProduct = benefitsRemaining + benefitUsedForSelectedQty;
      const qtyToAllow = Math.floor(benefitsRemainingWithCurrentProduct / benefitUsedByProduct);

      setQtyAvailableFromBenefits(qtyToAllow);
    }
  }, [benefitsByCategory, catUOM, quantity, qtyAvailableFromBenefits, selectedItem]);

  const options = [];
  const maxOptionsToAllow = 25;
  const maxInventory = (selectedItem.fiseq != null && selectedItem.fiseq.length > 0) ? Math.min(selectedItem.fiseq, maxOptionsToAllow) : maxOptionsToAllow;
  const maxQty = Math.min(qtyAvailableFromBenefits, maxInventory);

  for (let qty = 0; qty <= maxQty; qty += 1) {
    options.push(<option value={qty} key={qty}>{qty}</option>);
  }

  const addItem = () => {
    const alert = {
      allowOutsideClick: false,
      icon: 'error',
    };

    if (quantity < 0) {
      return MySwal.fire({
        ...alert,
        text: 'Please enter a Quantity to add this Product.',
      });
    }

    if (quantity > maxQty) {
      return MySwal.fire({
        ...alert,
        text: `This quantity is not available. The max quantity for this product is ${maxQty}.`,
      });
    }

    selectedItem.cart_quantity = quantity;

    let cart = {
      products: [],
    };
    let products = [];

    if (selectedProducts != null && selectedProducts.products != null) {
      cart = selectedProducts;
      products = selectedProducts.products || [];

      // Look for product already being in the cart
      products.forEach((product, index) => {
        if (product.prodkey === selectedItem.prodkey && product.catid === selectedItem.catid && product.subid === selectedItem.subid) {
          products.splice(index, 1);
        }
      });
    }

    // Add the product
    products.push(selectedItem);

    cart.products = products;

    // Update our cart products
    selectProducts(cart);

    // Recalculate the benefits
    updateBenefitsByCategory(cart.products);
    navigate('/item-categories', { replace: true });
  };

  return (
    <div className="ItemDetails">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          <div className="list add-item-list">
            <h3 className="item-name">
              {descript}
            </h3>
            <div className="thumbnail start">
              <img src={image_url} alt="" />
            </div>
            {catUOM !== '$$$' && (
              <h3 className="uom">
                {totalUOM} {catUOM}
              </h3>
            )}
            {catUOM === '$$$' && (
              <h3 className="text-primary">
                ${total}
              </h3>
            )}

            <FormGroup className="add-item row">
              <Label className="col-sm-2 col-form-label">Quantity:</Label>
              <div className="col-sm-10">
                <select className="form-control" {...bindQuantity}>
                  {options}
                </select>
              </div>
            </FormGroup>
            <Button color="primary" className="add-item-btn" block onClick={addItem}>Update Order</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetails;
