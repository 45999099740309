import React from 'react';
import PropTypes from 'prop-types';
import './CartCategoriesList.scss';
import { CartCategory } from '../CartCategory';

function CartCategoriesList({ categories }) {
  const itemCats = [];

  Object.entries(categories).forEach(([, group]) => {
    const { Cat, DecrSP, image_url, products, SubCat, UOMSP } = group;

    itemCats.push(
      <CartCategory
        key={Cat}
        catId={Cat}
        subCatId={SubCat}
        description={DecrSP}
        imageUrl={image_url}
        products={products}
        uom={UOMSP}
      />,
    );
  });

  return (
    <div className="CartCategoriesList">
      {itemCats}
    </div>
  );
}

CartCategoriesList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
};

CartCategoriesList.defaultProps = {
  categories: [],
};

export default CartCategoriesList;
