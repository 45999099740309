import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './CartProduct.scss';
import classNames from 'classnames';
import { parseCommaFloat } from '../../utils';

function CartProduct(props) {
  const { cartPrice, cartQty, description, imageUrl, size, uom } = props;

  const cartBftQty = (cartQty * parseCommaFloat(size));

  return (
    <FormGroup className="CartProduct row mx-0">
      <div className="thumbnail col-3 col-sm-2 text-center">
        <img src={imageUrl} alt="" />
      </div>
      <div className="description col-5 col-sm-6 col-md-5 col-lg-6">
        <span className="d-block text-truncate">
          {description}
          {uom !== '$$$' && (<><br />{size} {uom}</>)}
        </span>
      </div>
      <div className="col-4 col-sm-4 col-md-5 col-lg-4 ms-auto text-right">
        <span className={classNames('d-none', 'd-sm-inline')}>Qty: </span>
        <span className="text-success">
          {cartQty}
        </span>
        <span className={classNames('d-none', 'd-sm-inline')}> | </span>
        <span className={classNames('d-none', 'd-md-inline')}>Total: </span>
        <span className="text-primary">
          {uom === '$$$' ? <> ${cartPrice.toFixed(2)}</> : <> {cartBftQty} {uom}</>}
        </span>
      </div>
    </FormGroup>
  );
}

CartProduct.propTypes = {
  cartPrice: PropTypes.any,
  cartQty: PropTypes.number,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  size: PropTypes.string,
  uom: PropTypes.string,
};

CartProduct.defaultProps = {
  cartPrice: 0,
  cartQty: 0,
  description: null,
  imageUrl: null,
  size: null,
  uom: null,
};

export default CartProduct;
