import * as types from './types';

import { createReducer, reducers } from '../../utils';

/* State shape
{
  categories: [
    {
      Cat: '51',
      DecrSP: 'Milk/Leche Whole',
      UOM: 'GAL',
      products: [
        {
          bftqty: '1',
          posmenu: 'MILK',
          sku: 'ML25',
          descript: 'WHOLE MILK GALLON',
          price: '4.5',
          type: '1GALMILK',
          wictype: '1GALMILK',
          seq: '10',
          size: '128',
          sku2: 'ML01',
          uom: '6',
          uomdesc: 'oz',
          parlevel: '',
          storeno: 'C',
          mas90sku: 'ML01',
          ptnprice: '4.5',
          ptnprint: '2',
          physcnt: 'Y',
          promo: '',
          prodkey: '22055',
          prodgroup: 'A',
          prodcost: '2.4333',
          weight: '0',
          supplyform: '',
          whsetrans: '',
          milkrec: 'Y',
          pmultiplier: '',
          pullexclude: 'Y',
          freepromo: '',
          qtyonhand: '',
          madrtype: 'REG',
          amta: '4',
          amtb: '0',
          amtc: '0',
          itemsku: 'ML01',
          madrprice: '4.05',
          ptnstatus: 'A',
          figroup: 'ML25',
          fiseq: '100',
          promotype: '',
          recproduce: 'N',
          countsort: '160',
          prodorder: 'N',
          newpromo: '',
          addgroup1: '',
          addgroup2: '',
          promopick: 'N',
          labels: 'A',
          foodebt: 'Y',
          taxable: 'N',
          prodsku: '070910000148',
          upcpluind: '0',
          wicupcplu: '070910000148',
          image_url: 'https://primetimenutrition.s3-us-west-1.amazonaws.com/appimages/ML01_whole_milk.jpg',
          catid: '51',
          catUOM: 'GAL'
        },
        {
          bftqty: '0.5',
          posmenu: 'MILK',
          sku: 'ML35',
          descript: 'WHOLE MILK HALF-GAL',
          price: '2.75',
          type: '1HLFMILK',
          wictype: '1HLFMILK',
          seq: '30',
          size: '64',
          sku2: 'ML02',
          uom: '12',
          uomdesc: 'oz',
          parlevel: '',
          storeno: 'C',
          mas90sku: 'ML02',
          ptnprice: '2.75',
          ptnprint: '2',
          physcnt: 'Y',
          promo: '',
          prodkey: '22057',
          prodgroup: 'A',
          prodcost: '1.395',
          weight: '0',
          supplyform: '',
          whsetrans: '',
          milkrec: 'Y',
          pmultiplier: '',
          pullexclude: 'Y',
          freepromo: '',
          qtyonhand: '',
          madrtype: 'REG',
          amta: '3',
          amtb: '6',
          amtc: '6',
          itemsku: 'ML02',
          madrprice: '1.62',
          ptnstatus: 'A',
          figroup: 'ML35',
          fiseq: '101',
          promotype: '',
          recproduce: 'N',
          countsort: '160',
          prodorder: 'N',
          newpromo: '',
          addgroup1: '',
          addgroup2: '',
          promopick: 'N',
          labels: 'Y',
          foodebt: 'Y',
          taxable: 'N',
          prodsku: '070910000438',
          upcpluind: '0',
          wicupcplu: '070910000438',
          image_url: 'https://primetimenutrition.s3-us-west-1.amazonaws.com/appimages/ML02_whole_half_gallon_milk.jpg',
          catid: '51',
          catUOM: 'GAL'
        },
        {
          bftqty: '0.5',
          posmenu: 'MILK',
          sku: 'ML30',
          descript: 'LACTAID HALF-GAL WHL',
          price: '5.25',
          type: '1GALMILK',
          wictype: '1GALMILK',
          seq: '40',
          size: '64',
          sku2: 'ML24',
          uom: '12',
          uomdesc: 'oz',
          parlevel: '',
          storeno: 'C',
          mas90sku: 'ML24',
          ptnprice: '5.25',
          ptnprint: '2',
          physcnt: 'Y',
          promo: '',
          prodkey: '22172',
          prodgroup: 'A',
          prodcost: '2.5133',
          weight: '0',
          supplyform: '',
          whsetrans: '',
          milkrec: 'Y',
          pmultiplier: '',
          pullexclude: 'Y',
          freepromo: '',
          qtyonhand: '',
          madrtype: 'ACID',
          amta: '1',
          amtb: '0',
          amtc: '0',
          itemsku: 'ML24',
          madrprice: '3.98',
          ptnstatus: 'A',
          figroup: 'LACTWHLHLF',
          fiseq: '143',
          promotype: '',
          recproduce: 'N',
          countsort: '160',
          prodorder: 'N',
          newpromo: '',
          addgroup1: '',
          addgroup2: '',
          promopick: 'N',
          labels: 'Y',
          foodebt: 'Y',
          taxable: 'N',
          prodsku: '070910000360',
          upcpluind: '0',
          wicupcplu: '070910000360',
          image_url: 'https://primetimenutrition.s3-us-west-1.amazonaws.com/appimages/ML24_lactoid_whole_halfgal.jpg',
          catid: '51',
          catUOM: 'GAL'
        },
        {
          bftqty: '1',
          posmenu: 'MILK',
          sku: 'ML10',
          descript: 'EVAP MILK--REGULAR',
          price: '1.9',
          type: 'HLFMILK',
          wictype: 'MILK',
          seq: '61',
          size: '12',
          sku2: 'ML10',
          uom: '24',
          uomdesc: 'oz',
          parlevel: '0',
          storeno: 'C',
          mas90sku: 'ML10',
          ptnprice: '1.9',
          ptnprint: '0',
          physcnt: 'Y',
          promo: '',
          prodkey: '22186',
          prodgroup: 'A',
          prodcost: '1.1175',
          weight: '19.8',
          supplyform: '',
          whsetrans: 'Y',
          milkrec: '',
          pmultiplier: '',
          pullexclude: 'N',
          freepromo: '',
          qtyonhand: '',
          madrtype: 'ANY',
          amta: '1',
          amtb: '0',
          amtc: '0',
          itemsku: 'ML10',
          madrprice: '0.82',
          ptnstatus: 'A',
          figroup: 'EVAPREG',
          fiseq: '150',
          promotype: '',
          recproduce: 'N',
          countsort: '1',
          prodorder: 'N',
          newpromo: '',
          addgroup1: '',
          addgroup2: '',
          promopick: 'N',
          labels: 'Y',
          foodebt: 'Y',
          taxable: 'N',
          prodsku: '050000010110',
          upcpluind: '0',
          wicupcplu: '050000010110',
          image_url: 'https://s3-us-west-1.amazonaws.com/primetimenutrition/appimages/ML10_evap_milk.jpg',
          catid: '51',
          catUOM: 'GAL'
        }
      ]
    },
  ],
  categories_isFetching: false,
  lastBenefits: null,
  lastStore: null,
  selectedCategory: null,
  selectedProducts: null,
}
*/

const initialState = {
  categories: [],
  categories_isFetching: false,
  lastBenefits: null,
  lastStore: null,
  selectedCategory: null,
  selectedProducts: null,
};

const analyticsInsightLabelsItemReducer = createReducer(initialState)({
  [types.RECEIVE_ItemCategories_REQUEST]: (state, action) => reducers.requestReducer(state, action),
  [types.SET_ItemCategories_FETCHING]: (state, action) => reducers.fetchingReducer(state, action),
  [types.SET_ItemCategories_LOADING]: (state, action) => reducers.loadingReducer(state, action),
  [types.TOGGLE_ItemCategories_BOOLEAN]: (state, action) => reducers.booleanReducer(state, action),
});

export default analyticsInsightLabelsItemReducer;
